import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, map, Observable, shareReplay, Subject } from 'rxjs';
import { BundleModel, Company, CourseModel, Environment, ENVIRONMENT_TOKEN, PricingPlanLinks, UserModel } from '@lms/shared/models';
import { ReturnUrlAfterLoginStorage } from './return-url-after-login-storage.service';
import { COMPANIES_KEY, COMPANY_B2C_KEY } from '@lms/shared/constants';
import { GetActiveCompany } from './isB2BUser';

const FULL_IMMERSION_ROLE = 'full-immersion.role';
const SUBSCRIBER_IMMERSION_ROLE = 'subscribers.role';

@Injectable({ providedIn: 'root' })
export class CurrentUserStorage {
  public twinLmsUser!: { name: string; email: string };
  public countries$: BehaviorSubject<any[]> = new BehaviorSubject([] as any);
  public currentUser$: BehaviorSubject<UserModel | null>;
  public isB2BUser = false;
  private isB2BUser$$ = new BehaviorSubject(false);
  public isB2BUser$ = this.isB2BUser$$.asObservable();

  //TODO b2bUser is necessary remove from the code and fix related parts of the code
  public b2bUser = false;

  private b2bUserCurrentCompany$$ = new BehaviorSubject<Company | null>(null);
  public b2bUserCurrentCompany$ = this.b2bUserCurrentCompany$$.asObservable();
  private b2bUserBundleList$$ = new BehaviorSubject<BundleModel[] | null>(null);
  public b2bUserBundleList$ = this.b2bUserBundleList$$.asObservable().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  private b2bAssignedCourseList$$ = new BehaviorSubject<CourseModel[] | null>(null);
  public b2bAssignedCourseList$ = this.b2bAssignedCourseList$$.asObservable().pipe(shareReplay({ bufferSize: 1, refCount: true }));
  private b2cUserCurrentPreselectedLearningPathsCompany$$ = new BehaviorSubject<Company | null>(null);
  public b2cUserCurrentPreselectedLearningPathsCompany$ = this.b2cUserCurrentPreselectedLearningPathsCompany$$.asObservable();
  private b2cUserCurrentWithNoB2bLayoutEnabledCompanies$$ = new BehaviorSubject<Company[]>([]);
  public b2cUserCurrentWithNoB2bLayoutEnabledCompanies$ = this.b2cUserCurrentWithNoB2bLayoutEnabledCompanies$$.asObservable();
  private refreshStudentProfile$$ = new Subject<boolean>();
  public refreshStudentProfile$ = this.refreshStudentProfile$$.asObservable();

  public isMergedB2CDashboard$: Observable<boolean> = combineLatest([
    this.b2cUserCurrentPreselectedLearningPathsCompany$,
    this.b2cUserCurrentWithNoB2bLayoutEnabledCompanies$,
  ]).pipe(
    map(([preselectedCompany, companiesWithNoB2bLayoutEnabled]: [Company | null, Company[]]) => {
      return !!preselectedCompany && !!companiesWithNoB2bLayoutEnabled.length;
    }),
  );
  hasAccessToPremiumTools$: Observable<boolean>;
  public pricingPlanLinks$$: BehaviorSubject<PricingPlanLinks> = new BehaviorSubject({} as PricingPlanLinks);
  public pricingPlanLinks$: Observable<PricingPlanLinks> = this.pricingPlanLinks$$.asObservable();
  private isFullImmersion = false;
  private isSubscriber = false;

  registerSource: any = {
    simple: false,
    free: false,
    checkout: false,
    social: '',
  };

  fastEnrollScenario = false; // CFI_LMS-3775
  newFastEnrollScenario = `${this.env.onePageCheckout}` === '1'; // CFI_LMS-3775
  freeEnrollScenario = false; // CFI_LMS-3775

  constructor(
    private returnUrlAfterLoginStorage: ReturnUrlAfterLoginStorage,
    @Inject(ENVIRONMENT_TOKEN) public env: Environment,
  ) {
    this.currentUser$ = new BehaviorSubject<UserModel | null>(null);
    this.hasAccessToPremiumTools$ = this.currentUser$.pipe(
      map((user: UserModel | null) => !!user?.permissionObjects.includes(FULL_IMMERSION_ROLE) || !!user?.companies.length),
    );
  }

  processRegisterUrl(fastEnroll: boolean): void {
    this.registerSource.checkout = fastEnroll;
    this.registerSource.free = !this.registerSource.checkout && !!this.returnUrlAfterLoginStorage.getValue()?.match(/course.*enroll/);
    if (!this.registerSource.checkout && !this.registerSource.free) {
      this.registerSource.simple = true;
    }
    console.warn(this.registerSource);
    localStorage.setItem('registerSource', JSON.stringify(this.registerSource));
  }

  resolveRegisterSource(): string {
    try {
      const registerSource = JSON.parse(localStorage.getItem('registerSource') || '');
      if (registerSource.checkout) return 'checkout';
      if (registerSource.free) return 'free';
      if (registerSource.simple) return 'simple';
    } catch (e) {
      console.warn(e);
    }
    return 'null';
  }

  resolveSocialSource(): string {
    try {
      return localStorage.getItem('provider') || '';
    } catch (e) {
      console.warn(e);
    }
    return '';
  }

  getFrontendParameter(parameterName: string): any {
    return this.currentUser$.value?.frontendParameters?.find(parameter => parameter.code == parameterName)?.value;
  }

  getCurrentUserSubject(): BehaviorSubject<UserModel | null> {
    return this.currentUser$;
  }

  getCurrentUser(): UserModel | null {
    return this.currentUser$.value;
  }

  isUserFullImmersion(): boolean {
    return this.isFullImmersion;
  }

  isUserSubscriber(): boolean {
    return this.isSubscriber;
  }

  isFreeStudent(): boolean {
    return !this.getCurrentUser()?.enrolledBundles?.length && !this.isUserSubscriber() && !this.isUserFullImmersion();
  }

  getDismissedAlerts(): string[] {
    return this.currentUser$.value?.dismissedAlerts || [];
  }

  setDismissedAlerts(dismissedAlerts: string[]): void {
    const user = this.currentUser$.value;
    if (user) {
      user.dismissedAlerts = dismissedAlerts;
      this.currentUser$.next(user);
    }
  }

  getAuthorizationStatus(): boolean {
    return localStorage.getItem('authorizationStatus') === '1';
  }

  refreshStudentProfile(): void {
    this.refreshStudentProfile$$.next(true);
  }

  setCurrentUser(user: UserModel | null): void {
    this.currentUser$.next(user);
    this.checkUserRoles();

    if (user) {
      localStorage.setItem('authorizationStatus', '1');
    } else {
      localStorage.removeItem('authorizationStatus');
      return;
    }

    const companyToSet = GetActiveCompany(user);
    this.prepareB2cLearningPathsCompanies(user);

    if (companyToSet.id === 0) {
      this.setB2bUserCurrentCompany(null);
      this.setB2BUser(false);
    } else {
      this.setB2bUserCurrentCompany(companyToSet);

      const jsonData = JSON.stringify(companyToSet);
      localStorage.setItem(COMPANIES_KEY, jsonData);
      this.setB2BUser(true);
    }

    this.setIsB2BUser(!!user?.companies.length);
  }

  setB2BUser(b2b: boolean): void {
    this.b2bUser = b2b;

    this.setIsB2BUser(b2b);
  }

  setIsB2BUser(isB2B: boolean): void {
    this.isB2BUser$$.next(isB2B);
    this.isB2BUser = isB2B;
  }

  setB2bUserCurrentCompany(company: Company | null): void {
    this.b2bUserCurrentCompany$$.next(company);
  }

  setB2bUserBundleList(companyList: BundleModel[]): void {
    this.b2bUserBundleList$$.next(companyList);
  }

  setB2bAssignedCourseList(courses: CourseModel[]): void {
    this.b2bAssignedCourseList$$.next(courses);
  }

  setB2cPreselectedLearningPathsCompany(company: Company | null): void {
    localStorage.setItem(COMPANY_B2C_KEY, JSON.stringify(company || ''));
    this.b2cUserCurrentPreselectedLearningPathsCompany$$.next(company);
  }

  hasPermissionObject(permissionObject: string): boolean {
    return !!this.currentUser$.value?.permissionObjects.includes(permissionObject);
  }

  private prepareB2cLearningPathsCompanies(user: UserModel | null): void {
    if (!user) {
      return;
    }

    const companiesWithNoB2bLayoutEnabled = (user?.companies || []).filter((company: Company) => !company.b2bLayoutEnabled);
    this.b2cUserCurrentWithNoB2bLayoutEnabledCompanies$$.next(companiesWithNoB2bLayoutEnabled);

    const preselectedLearningPathsCompany = this.getB2cPreselectedLearningPathsCompany(companiesWithNoB2bLayoutEnabled);

    if (!preselectedLearningPathsCompany && companiesWithNoB2bLayoutEnabled[0]) {
      this.setB2cPreselectedLearningPathsCompany(companiesWithNoB2bLayoutEnabled[0]);
      return;
    }

    this.b2cUserCurrentPreselectedLearningPathsCompany$$.next(preselectedLearningPathsCompany || null);
  }

  private getB2cPreselectedLearningPathsCompany(companiesWithNoB2bLayoutEnabled: Company[]): Company | null {
    const company = localStorage.getItem(COMPANY_B2C_KEY);
    let parsedCompany: Company | null = null;
    try {
      parsedCompany = company ? JSON.parse(company) : null;
    } catch (e) {
      console.error('Error parsing company from local storage');
    }

    const isParsedCompanyInUserCompanies = (companiesWithNoB2bLayoutEnabled || []).find(
      (company: Company) => company.id === parsedCompany?.id,
    );

    return isParsedCompanyInUserCompanies ? { ...isParsedCompanyInUserCompanies } : null;
  }

  private checkUserRoles(): void {
    this.isFullImmersion = !!this.currentUser$.value?.permissionObjects.includes(FULL_IMMERSION_ROLE);
    this.isSubscriber = !!this.currentUser$.value?.permissionObjects.includes(SUBSCRIBER_IMMERSION_ROLE);
  }
}
